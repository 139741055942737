export const bookData = [
  {
    name: "Biology Compressed",
    class: "Class 11",
    subject: "Biology",
    thumbnail: "bookcovers/bio11.png",
    href: "bio11-compressed.pdf",
    // href: 'https://sin1.contabostorage.com/2855e1c7d1e94cbabbf72848dc7da005:aibox-digital-library/bio11.pdf',
  },
  {
    name: "Biology",
    class: "Class 11",
    subject: "Biology",
    thumbnail: "bookcovers/bio11.png",
    href: "bio11.pdf",
    // href: 'https://sin1.contabostorage.com/2855e1c7d1e94cbabbf72848dc7da005:aibox-digital-library/bio11.pdf',
  },
  {
    name: "Maths",
    class: "Class 12",
    subject: "Maths",
    thumbnail: "bookcovers/math12.png",
    href: "pdf.pdf",
    // href: 'https://sin1.contabostorage.com/2855e1c7d1e94cbabbf72848dc7da005:aibox-digital-library/maths12.pdf',
  },
  {
    name: "Chemistry",
    class: "Class 12",
    subject: "Chemistry",
    thumbnail: "bookcovers/chem12.png",
    href: "chemistry12.pdf",
    // href: 'https://sin1.contabostorage.com/2855e1c7d1e94cbabbf72848dc7da005:aibox-digital-library/chemistry12.pdf',
  },
  {
    name: "Maths",
    class: "Class 11",
    subject: "Maths",
    thumbnail: "bookcovers/maths11.png",
    href: "maths11.pdf",
    // href: 'https://sin1.contabostorage.com/2855e1c7d1e94cbabbf72848dc7da005:aibox-digital-library/maths11.pdf'
  },
];
