import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import BookViewer from './BookViewer';

const urlParams = new URLSearchParams(window.location.search);
const bookpdflink = urlParams.get('bookpdflink');

const root = ReactDOM.createRoot(document.getElementById('root'));

if (bookpdflink) {
    root.render(<BookViewer />);
} else {
    root.render(<App />);
}
