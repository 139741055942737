import React, { useEffect } from "react";
import List from "list.js"; // Import List.js
import "./App.css"; // Your styles
import { bookData } from "./constants/bookData";

const App = () => {
  useEffect(() => {
    // Initialize class and subject options
    const classSet = new Set();
    const subjectSet = new Set();

    bookData.forEach((book) => {
      classSet.add(book.class);
      subjectSet.add(book.subject);
    });

    const classSelect = document.getElementById("classSelect");
    const subjectSelect = document.getElementById("subjectSelect");

    classSet.forEach((classItem) => {
      const option = document.createElement("option");
      option.value = classItem;
      option.textContent = classItem;
      classSelect.appendChild(option);
    });

    subjectSet.forEach((subjectItem) => {
      const option = document.createElement("option");
      option.value = subjectItem;
      option.textContent = subjectItem;
      subjectSelect.appendChild(option);
    });

    // Define List.js options
    const options = {
      valueNames: [
        "name",
        { data: ["class", "subject", "href"] },
        { name: "thumbnail", attr: "src" },
      ],
      item: '<div class="card" data-class="" data-subject="" data-href=""><a class="bookthumbnail" href=""><img class="thumbnail" alt=""></a><div class="bookname name"></div></div>',
    };

    // Initialize List.js
    const bookList = new List("book-list", options, bookData);

    // Define filter function
    const filterBooks = () => {
      const selectedClass = classSelect.value;
      const selectedSubject = subjectSelect.value;

      bookList.filter((item) => {
        const isClassMatch =
          !selectedClass || item.values()["class"] === selectedClass;
        const isSubjectMatch =
          !selectedSubject || item.values()["subject"] === selectedSubject;
        return isClassMatch && isSubjectMatch;
      });
    };

    // Add event listeners for filtering
    classSelect.addEventListener("change", filterBooks);
    subjectSelect.addEventListener("change", filterBooks);

    // Update href attributes after list update
    const updateHrefAttributes = () => {
      document.querySelectorAll(".bookthumbnail").forEach((anchor) => {
        const card = anchor.closest(".card");
        const href = card.getAttribute("data-href");
        anchor.addEventListener("click", (e) => {
          e.preventDefault();
          window.location.href = `/book?bookpdflink=${href}`;
        });
      });
    };

    bookList.on("updated", updateHrefAttributes);

    // Initial set of href attributes
    updateHrefAttributes();

    // Clean up event listeners on component unmount
    return () => {
      classSelect.removeEventListener("change", filterBooks);
      subjectSelect.removeEventListener("change", filterBooks);
    };
  }, []);

  return (
    <div className="App">
      <div className="top">
        <div className="left">
          <h1>Find the Book you are looking for easier to Read</h1>
          <select id="classSelect">
            <option value="">Select class</option>
          </select>
          <select id="subjectSelect">
            <option value="">Select subject</option>
          </select>
        </div>
        <div className="right">
          <img src="Frame.svg" alt="Frame" />
        </div>
      </div>
      <center>
        <div className="bottom">
          <div id="book-list">
            <div className="list" style={{ display: "flex" }}>
              {/* Items will be injected automatically */}
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default App;
