import React, { useEffect, useRef, useState } from "react";
import "./BookViewer.css";

const BookViewer = () => {
  const fabric = window.fabric;

  const urlParams = new URLSearchParams(window.location.search);
  const bookpdflink = urlParams.get("bookpdflink");
  const url = bookpdflink ? `bookpdf/${bookpdflink}` : "bookpdf/unknown.pdf";
  const filename = url.split("/").pop();

  const [pdfDoc, setPdfDoc] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [annotations, setAnnotations] = useState(
    JSON.parse(localStorage.getItem(`${filename}_annotations`)) || {}
  );
  const [renderedPages, setRenderedPages] = useState([]);

  const canvasLeftRef = useRef(null);
  const canvasRightRef = useRef(null);
  const fabricCanvasLeftRef = useRef(null);
  const fabricCanvasRightRef = useRef(null);

  useEffect(() => {
    const canvasLeft = canvasLeftRef.current;
    const canvasRight = canvasRightRef.current;

    const fabricCanvasLeft = new fabric.Canvas("annotation-canvas-left", {
      selection: false,
    });
    fabricCanvasLeftRef.current = fabricCanvasLeft;

    const fabricCanvasRight = new fabric.Canvas("annotation-canvas-right", {
      selection: false,
    });
    fabricCanvasRightRef.current = fabricCanvasRight;

    fabricCanvasLeft.isDrawingMode = false;
    fabricCanvasRight.isDrawingMode = false;

    window.pdfjsLib.getDocument(url).promise.then((pdfDoc_) => {
      setPdfDoc(pdfDoc_);
      renderInitialPages(pdfDoc_, canvasLeft, canvasRight, fabricCanvasLeft, fabricCanvasRight);
    });
  }, [url]);

  const renderInitialPages = (pdfDoc_, canvasLeft, canvasRight, fabricCanvasLeft, fabricCanvasRight) => {
    return Promise.all([
      renderPage(pdfDoc_, pageNum, canvasLeft, fabricCanvasLeft),
      renderPage(pdfDoc_, pageNum + 1, canvasRight, fabricCanvasRight),
    ]).then(() => {
      preRenderRemainingPages(pdfDoc_);
    });
  };

  const preRenderRemainingPages = (pdfDoc_) => {
    const promises = [];
    for (let i = 1; i <= pdfDoc_.numPages; i++) {
      if (i !== pageNum && i !== pageNum + 1) {
        promises.push(renderPageToImage(pdfDoc_, i));
      }
    }
    return Promise.all(promises);
  };

  const renderPageToImage = (pdfDoc_, pageNum) => {
    return pdfDoc_.getPage(pageNum).then((page) => {
      const viewport = page.getViewport({ scale: calculateScale(page) });
      const canvas = document.createElement("canvas");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const ctx = canvas.getContext("2d");

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };

      return page.render(renderContext).promise.then(() => {
        setRenderedPages((prev) => ({
          ...prev,
          [pageNum]: canvas.toDataURL(),
        }));
      });
    });
  };

  const renderPage = (pdfDoc_, num, canvas, fabricCanvas) => {
    return pdfDoc_.getPage(num).then((page) => {
      const viewport = page.getViewport({ scale: calculateScale(page) });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      fabricCanvas.setHeight(viewport.height);
      fabricCanvas.setWidth(viewport.width);

      const ctx = canvas.getContext("2d");

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };

      return page.render(renderContext).promise.then(() => {
        setRenderedPages((prev) => ({
          ...prev,
          [num]: canvas.toDataURL(),
        }));
        loadAnnotations(num, fabricCanvas);
      });
    });
  };

  const calculateScale = (page) => {
    const desiredHeight = window.innerHeight * 0.9;
    const viewport = page.getViewport({ scale: 1 });
    return desiredHeight / viewport.height;
  };

  const loadAnnotations = (pageNum, fabricCanvas) => {
    if (annotations[pageNum]) {
      fabricCanvas.clear();
      fabricCanvas.loadFromJSON(annotations[pageNum]);
    }
  };

  const saveAnnotations = () => {
    const updatedAnnotations = {
      ...annotations,
      [pageNum]: fabricCanvasLeftRef.current.toJSON(),
      [pageNum + 1]: fabricCanvasRightRef.current.toJSON(),
    };
    setAnnotations(updatedAnnotations);
    localStorage.setItem(
      `${filename}_annotations`,
      JSON.stringify(updatedAnnotations)
    );
    window.history.back();
  };

  const handleButtonClick = (action) => {
    const fabricCanvasLeft = fabricCanvasLeftRef.current;
    const fabricCanvasRight = fabricCanvasRightRef.current;

    switch (action) {
      case "pen":
        fabricCanvasLeft.isDrawingMode = true;
        fabricCanvasLeft.freeDrawingBrush = new fabric.PencilBrush(fabricCanvasLeft);
        fabricCanvasLeft.freeDrawingBrush.color = "black";
        fabricCanvasLeft.freeDrawingBrush.width = 2;

        fabricCanvasRight.isDrawingMode = true;
        fabricCanvasRight.freeDrawingBrush = new fabric.PencilBrush(fabricCanvasRight);
        fabricCanvasRight.freeDrawingBrush.color = "black";
        fabricCanvasRight.freeDrawingBrush.width = 2;
        break;

      case "highlighter":
        fabricCanvasLeft.isDrawingMode = true;
        fabricCanvasLeft.freeDrawingBrush = new fabric.PencilBrush(fabricCanvasLeft);
        fabricCanvasLeft.freeDrawingBrush.color = "rgba(255, 255, 0, 0.5)";
        fabricCanvasLeft.freeDrawingBrush.width = 20;

        fabricCanvasRight.isDrawingMode = true;
        fabricCanvasRight.freeDrawingBrush = new fabric.PencilBrush(fabricCanvasRight);
        fabricCanvasRight.freeDrawingBrush.color = "rgba(255, 255, 0, 0.5)";
        fabricCanvasRight.freeDrawingBrush.width = 20;
        break;

      case "eraser":
        fabricCanvasLeft.isDrawingMode = true;
        fabricCanvasLeft.freeDrawingBrush = new fabric.EraserBrush(fabricCanvasLeft);
        fabricCanvasLeft.freeDrawingBrush.width = 30;

        fabricCanvasRight.isDrawingMode = true;
        fabricCanvasRight.freeDrawingBrush = new fabric.EraserBrush(fabricCanvasRight);
        fabricCanvasRight.freeDrawingBrush.width = 30;
        break;

      case "normal":
        fabricCanvasLeft.isDrawingMode = false;
        fabricCanvasRight.isDrawingMode = false;
        break;

      case "next":
        if (pageNum + 1 < pdfDoc.numPages) {
          saveAnnotations();
          setPageNum((prev) => prev + 2);
          renderPages();
        }
        break;

      case "previous":
        if (pageNum > 1) {
          saveAnnotations();
          setPageNum((prev) => prev - 2);
          renderPages();
        }
        break;

    case "back":
        document.getElementById("save-modal").style.display = "block";
        break;

      default:
        break;
    }
  };

  const renderPages = () => {
    const canvasLeft = canvasLeftRef.current;
    const canvasRight = canvasRightRef.current;
    const fabricCanvasLeft = fabricCanvasLeftRef.current;
    const fabricCanvasRight = fabricCanvasRightRef.current;

    renderImage(pageNum, canvasLeft, fabricCanvasLeft);
    renderImage(pageNum + 1, canvasRight, fabricCanvasRight);
  };

  const renderImage = (pageNum, canvas, fabricCanvas) => {
    if (renderedPages[pageNum]) {
      const img = new Image();
      img.src = renderedPages[pageNum];
      img.onload = () => {
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        fabricCanvas.setWidth(img.width);
        fabricCanvas.setHeight(img.height);
        loadAnnotations(pageNum, fabricCanvas);
      };
    } else {
      renderPage(pdfDoc, pageNum, canvas, fabricCanvas);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (Object.keys(annotations).length > 0) {
        e.preventDefault();
        e.returnValue = ""; // For older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [annotations]);

  return (
    <div className="pdf-annotator">
      <button className="back-button" onClick={() => handleButtonClick("back")}>
        <i className="bi bi-arrow-left-circle"></i>
      </button>
      <div className="toolbar">
        <button onClick={() => handleButtonClick("pen")}>
          <i className="bi bi-pen"></i>
        </button>
        <button onClick={() => handleButtonClick("highlighter")}>
          <i className="bi bi-highlighter"></i>
        </button>
        <button onClick={() => handleButtonClick("eraser")}>
          <i className="bi bi-eraser-fill"></i>
        </button>
        <button onClick={() => handleButtonClick("normal")}>
          <i className="bi bi-cursor-fill"></i>
        </button>
        <button onClick={() => handleButtonClick("next")}>
          <i className="bi bi-chevron-right"></i>
        </button>
        <button onClick={() => handleButtonClick("previous")}>
          <i className="bi bi-chevron-left"></i>
        </button>
      </div>
      <div className="pdf-container">
        <div className="page">
          <canvas id="pdf-canvas-left" ref={canvasLeftRef}></canvas>
          <canvas
            id="annotation-canvas-left"
            className="annotation-layer"
          ></canvas>
        </div>
        <div className="page">
          <canvas id="pdf-canvas-right" ref={canvasRightRef}></canvas>
          <canvas
            id="annotation-canvas-right"
            className="annotation-layer"
          ></canvas>
        </div>
      </div>
      <div id="save-modal" className="modal">
        <div className="modal-content">
          <p>Do you want to save your annotations?</p>
          <div className="modal-buttons">
            <button className="modal-button save" onClick={() => saveAnnotations()}>
              Save
            </button>
            <button className="modal-button cancel" onClick={() => window.history.back()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookViewer;
